if (document.querySelectorAll('[data-component="hamburger"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        const toggle = document.querySelector('[data-toggle="hamburger"]');
        const collapsed = document.querySelector('[data-target="hamburger"]');
        const expanded = collapsed.getAttribute('aria-expanded');

        toggle.addEventListener('click', function () {
            collapsed.classList.toggle('show');

            if (expanded == 'true') {
                expanded = 'false';
            } else {
                expanded = 'true';
            }

            collapsed.setAttribute('aria-expanded', expanded)
        })
    })
}