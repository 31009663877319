import Modal from 'modal-vanilla';

if (document.querySelectorAll('[data-component="modal"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        document.querySelector('.modal-terms').addEventListener('click', function () {  
            new Modal({
                el: document.getElementById('modal-terms')
            }).show();
        })
    })
}